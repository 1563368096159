import SvgXa from '@assets/icons/Xa';
import { UserButtonSmall } from '@components/Buttons/UserButton/UserButtonSmall';
import { NAV_LINKS } from '@constants/nav';
import { Group, ScrollArea } from '@mantine/core';
import { useAuth } from '@provider/AuthContext';
import { Link } from '@tanstack/react-router';
import { LinksGroupCollapsed } from '../NavbarLinksGroup/NavbarLinksGroup';

const NavbarCollapsed = () => {
  const { user } = useAuth();
  const links = NAV_LINKS.map((item) => <LinksGroupCollapsed {...item} key={item.id} />);

  return (
    <nav className='bg-primary-black h-full flex flex-col pt-4 pb-0 w-fit'>
      <div className='border-b border-divider px-4 pb-4'>
        <Group className='justify-between'>
          <Link to={'/'} className='flex items-center'>
            <SvgXa />
          </Link>
        </Group>
      </div>

      <ScrollArea className='flex-1'>
        <div className='py-8 flex flex-col gap-4 px-2'>{links}</div>
      </ScrollArea>

      <div className='border-t border-divider'>
        <UserButtonSmall user={user} />
      </div>
    </nav>
  );
};

export default NavbarCollapsed;
