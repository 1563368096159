import { Footer } from '@components/Base/Footer/Footer';
import { HeaderMegaMenu } from '@components/Base/Header/HeaderMegaMenu';
import MobileHeader from '@components/Base/Header/MobileHeader';
import SidebarAnimatedWrapper from '@components/Base/Sidebar/SidebarAnimatedWrapper';
import { SCREEN_SIZE } from '@constants/enums';
import { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from '@constants/style';
import useLogout from '@hooks/useLogout';
import useTermsOfUseAccepted from '@hooks/useTermsOfUseAccepted';
import { useWindowSize } from '@hooks/useWindowSize';
import { LoadingOverlay } from '@mantine/core';
import { useAuth } from '@provider/AuthContext';
import { useLocation, useNavigate } from '@tanstack/react-router';
import { cn } from '@utils/utils';
import { PropsWithChildren, useEffect } from 'react';
import TermsOfUseLayoutAcceptanceLayout from './TermsOfUseLayoutAcceptanceLayout';

type AuthenticatedLayoutProps = {
  isExpanded: boolean;
};

const AuthenticatedLayout = ({
  isExpanded,
  children,
}: PropsWithChildren<AuthenticatedLayoutProps>) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { user } = useAuth();
  const { handleLogout } = useLogout();
  const { isTermOfUseAccepted, isLoading, refetch } = useTermsOfUseAccepted();
  const { width } = useWindowSize();

  useEffect(() => {
    refetch();

    if (isLoading) {
      return;
    }

    if (!isTermOfUseAccepted) {
      navigate({ to: '/terms' });
      return;
    }
  }, [pathname, navigate, isTermOfUseAccepted, isLoading, refetch]);

  if (isLoading) {
    return <LoadingOverlay visible={true} loaderProps={{ color: 'red' }} />;
  }

  if (!user || !user?.['record id']) {
    handleLogout();
  }

  if (!isTermOfUseAccepted) {
    return <TermsOfUseLayoutAcceptanceLayout>{children}</TermsOfUseLayoutAcceptanceLayout>;
  }

  return (
    <>
      <div
        className={cn(
          `h-full w-full grid grid-cols-[300px_repeat(12,1fr)] transition-all duration-300`,
          {
            'grid-cols-[82px_repeat(12,1fr)] opacity-100': !isExpanded,
          },
        )}
        style={{
          marginTop: width >= SCREEN_SIZE.LG ? HEADER_HEIGHT_DESKTOP : HEADER_HEIGHT_MOBILE,
        }}
      >
        {width >= SCREEN_SIZE.LG ? (
          <>
            <HeaderMegaMenu />
            <SidebarAnimatedWrapper />
          </>
        ) : (
          <>
            <MobileHeader />
          </>
        )}

        <div className='flex flex-col col-start-1 lg:col-start-2 col-span-full'>
          {children}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AuthenticatedLayout;
