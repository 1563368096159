import SvgRedXa from '@assets/icons/RedXA';
import { IconDirectionSign } from '@tabler/icons-react';
import { createFileRoute, Link, redirect } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import tw from 'tailwind-styled-components';
import Vid from '../assets/video/Vid.mp4';

export const Route = createFileRoute('/')({
  beforeLoad: async ({ context }) => {
    const userAuth = context.authentication?.user?.jwt;
    if (userAuth) {
      throw redirect({ to: '/home' });
    }
  },
  component: WelcomePage,
});

const WelcomeParagraph = tw.p`
  text-white text-lg font-sans w-full max-w-full text-center leading-6  
  md:text-[24px] md:max-w-[80%] md:leading-8
  lg:text-[30px] lg:max-w-[62%] lg:leading-10
`;

function WelcomePage() {
  return (
    <div className='relative flex flex-col h-full overflow-hidden'>
      <video className='absolute object-cover w-full h-full' autoPlay loop muted>
        <source src={Vid} type='video/mp4' />
      </video>
      <div className='absolute inset-0 bg-black opacity-50' />
      <div className='relative z-floating flex flex-col gap-10 lg:gap-5 items-center justify-center w-full h-full p-4'>
        <div className='flex items-center justify-center w-full flex-col lg:flex-row gap-10 lg:gap-0'>
          <SvgRedXa />
          <WelcomeParagraph>
            The investment network that fosters
            <b className='font-semibold'> inclusive innovation</b> by empowering the tech community
            in <b className='font-semibold'>Southeast Asia</b> and{' '}
            <b className='font-semibold'>Africa</b>
          </WelcomeParagraph>
        </div>

        <BouncingButton>
          <Link to='/login' className='flex items-center justify-between gap-2'>
            <div className='span'>Get started</div>
            <IconDirectionSign stroke={2} />
          </Link>
        </BouncingButton>
      </div>
    </div>
  );
}

const BouncingButton = ({ children }: { children: React.ReactNode }) => {
  return (
    <motion.button
      className='inline-flex py-2.5 px-5 rounded text-lg border-none bg-darkest-red hover:cursor-pointer text-white'
      whileHover={{
        scale: 1.2,
        transition: { duration: 0.1 },
      }}
      whileTap={{ scale: 1.1 }}
      animate={{
        y: ['0%', '-20%', '0%'],
      }}
      transition={{
        y: {
          duration: 1,
          repeat: Infinity,
          repeatType: 'reverse',
        },
        scale: {
          duration: 0.1,
        },
      }}
    >
      {children}
    </motion.button>
  );
};
