import { Company } from '@type/InvestmentTable/type';

interface CompanyNameColumnProps {
  company: Company;
}

const CompanyNameColumn = ({ company }: CompanyNameColumnProps) => {
  return (
    <div className='flex items-center gap-3 overflow-x-hidden'>
      <img
        className='object-scale-down rounded-full size-10 lg:size-14 mix-blend-multiply'
        src={
          company.Logo?.[0]?.url ||
          'https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png'
        }
        alt={company['Company Name']}
        loading='lazy'
      />
      <span className='font-sans flex-1 truncate text-base leading-6 font-bold pl-2'>
        {company['Company Name']}
      </span>
    </div>
  );
};

export default CompanyNameColumn;
