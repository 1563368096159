import * as React from 'react';

export interface User {
  // Linkedin user
  email: string;
  'record id': string;
  jwt?: string;
  name?: string;

  firstName?: string;
  lastName?: string;
  locale?: {
    country?: string;
    language?: string;
  };
  picture?: string;

  // Normal user
  'full name'?: string;
  'first name'?: string;
  'last name'?: string;
  role?: string;
  'xa email'?: string;
}

export interface AuthContext {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}
const AuthContext = React.createContext<AuthContext>({
  setUser: () => {},
  user: null,
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<User | null>(
    localStorage.getItem('xa-network')
      ? JSON.parse(localStorage.getItem('xa-network') as string)
      : null,
  );

  const value = React.useMemo(
    () => ({
      user,
      setUser,
    }),
    [user, setUser],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
