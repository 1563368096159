import { Box, Button, Checkbox, Input } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useEffect, useState } from 'react';

interface CheckboxGroupFilterProps {
  name: string;
  title: string;
  defaultOptions: string[];
  formInstance: UseFormReturnType<any>;
}

const CheckboxGroupFilter = ({
  name,
  title,
  defaultOptions,
  formInstance,
}: CheckboxGroupFilterProps) => {
  const { setFieldValue, getValues } = formInstance;
  const [formValues, setFormValues] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const currentValues = getValues();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setFormValues(currentValues[name]);
  }, [currentValues, name]);

  // Reset form values when default options change
  useEffect(() => {
    if (currentValues[name].length !== defaultOptions.length) {
      return;
    }

    setFormValues(defaultOptions);
  }, [getValues, currentValues, setFormValues, defaultOptions, name]);

  const handleChange = (values: string[]) => {
    setFormValues(values);
    setFieldValue(name, values);
  };

  const handleSelectAll = () => {
    setFormValues(defaultOptions);
    setFieldValue(name, defaultOptions);
  };

  const handleDeselectAll = () => {
    setFormValues([]);
    setFieldValue(name, []);
  };

  const renderOptions = searchTerm
    ? defaultOptions.filter((option) => option.toLowerCase().includes(searchTerm.toLowerCase()))
    : defaultOptions;

  return (
    <div className='flex flex-col gap-2 font-sans'>
      <h5 className='text-lg leading-7 font-medium text-primary-black'>{title}</h5>
      <div className='flex items-center justify-between'>
        <Button
          disabled={!!searchTerm}
          variant='transparent'
          color='red'
          className='p-0 bg-transparent font-sans'
          onClick={handleSelectAll}
        >
          Select All
        </Button>
        <Button
          disabled={!!searchTerm}
          variant='transparent'
          color='gray'
          className='p-0 bg-transparent font-sans'
          onClick={handleDeselectAll}
        >
          Clear
        </Button>
      </div>
      <div>
        <Input
          onChange={handleSearch}
          placeholder='Search'
          variant='unstyled'
          className='border-b border-gray'
        />
      </div>
      <Checkbox.Group value={formValues} onChange={handleChange}>
        <Box className='flex flex-col gap-2 text-tetiary-black py-2'>
          {renderOptions.map((value) => {
            return <Checkbox key={value} radius='sm' color='red' label={value} value={value} />;
          })}
        </Box>
      </Checkbox.Group>
    </div>
  );
};

export default CheckboxGroupFilter;
