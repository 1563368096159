import { FeedBackModal } from '@components/pages/FeedBackModal';
import useTermsOfUseAccepted from '@hooks/useTermsOfUseAccepted';
import { ActionIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAuth } from '@provider/AuthContext';
import { IconMessagePlus } from '@tabler/icons-react';

const FeedbackFloatButton = () => {
  const [isOpen, { open, close }] = useDisclosure(false);
  const { user } = useAuth();
  const { isTermOfUseAccepted } = useTermsOfUseAccepted();

  if (!user || !isTermOfUseAccepted) {
    return <></>;
  }

  return (
    <div>
      <FeedBackModal user={user} isOpen={isOpen} onClose={close} />
      <ActionIcon
        onClick={open}
        size='xl'
        variant='filled'
        radius='xl'
        className='fixed bottom-44 lg:bottom-24 right-4 size-14 hover:scale-105 z-floating animate-bounce'
        color='red'
      >
        <IconMessagePlus size={24} />
      </ActionIcon>
    </div>
  );
};

export default FeedbackFloatButton;
