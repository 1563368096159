import { NormalLink } from '@constants/nav';
import { UnstyledButton } from '@mantine/core';
import { Link } from '@tanstack/react-router';
import { cn } from '@utils/utils';

type NormalNavLinkProps = {
  isCollapsed?: boolean;
} & NormalLink;

const NormalNavLink = ({ isCollapsed = false, link, label, icon: Icon }: NormalNavLinkProps) => {
  return (
    <Link
      to={link}
      href={link}
      className='px-4 py-3 rounded-xl text-unactive-link transition hover:bg-dark-red hover:text-white'
      activeProps={{
        className: 'text-white bg-dark-red',
      }}
    >
      <UnstyledButton
        className={cn('gap-2.5 flex w-full items-center', {
          'justify-center': isCollapsed,
        })}
      >
        {Icon && <Icon stroke={1.5} width={26} height={26} className='shrink-0' />}
        {!isCollapsed && <span>{label}</span>}
      </UnstyledButton>
    </Link>
  );
};

export default NormalNavLink;
