import { SVGProps } from 'react';

const RedTextXA = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='91'
      height='40'
      viewBox='0 0 91 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_878_195)'>
        <path
          d='M14.1653 16.3142L12.5313 13.7922H12.5208L10.8447 16.3142H8.89014L11.5225 12.6153L8.96369 8.96362H11.0706L12.6101 11.3385H12.6206L14.2179 8.96362H16.1356L13.5926 12.4629L16.2617 16.3195H14.1653V16.3142Z'
          fill='#F43D4B'
        />
        <path
          d='M11.1388 29.3866L10.5819 30.9996H8.88477L11.4278 23.6963H13.603L16.2564 30.9996H14.4437L13.8552 29.3866H11.1388ZM13.4874 28.0205C12.9778 26.5388 12.6468 25.5563 12.4629 24.8837H12.4523C12.2685 25.6193 11.9007 26.7542 11.4908 28.0205H13.4874Z'
          fill='#F43D4B'
        />
        <path
          d='M23.6963 31.0785V38.4448H1.51846V31.0785H0V40.0001H25.2095V31.0785H23.6963Z'
          fill='#F43D4B'
        />
        <path
          d='M1.51846 8.92158V1.55523H23.6963V8.92158H25.2095V0H0V8.92158H1.51846Z'
          fill='#F43D4B'
        />
        <path
          d='M44.5345 17.5439H41.8811V16.3092H48.7063V17.5439H46.0634V23.6335H44.5345V17.5439Z'
          fill='#F43D4B'
        />
        <path
          d='M52.878 23.6335L50.729 16.3092H52.3315C52.7991 18.0378 53.6608 21.038 53.8237 22.2412H53.8342C54.0601 20.9959 54.9534 18.0378 55.4788 16.3092H56.9499C57.4333 17.9327 58.3791 21.2166 58.563 22.1939H58.5787C58.7994 20.9381 59.6558 17.9748 60.1235 16.3092H61.6577L59.3669 23.6335H57.7118C57.2232 21.9995 56.3352 18.9048 56.1145 17.917H56.104C55.8939 19.0571 55.0584 21.8103 54.512 23.6335H52.8727H52.878Z'
          fill='#F43D4B'
        />
        <path
          d='M71.3883 19.9501C71.3883 21.994 70.017 23.7909 67.4267 23.7909C64.9835 23.7909 63.5491 22.0938 63.5491 19.9501C63.5491 17.9273 65.057 16.1514 67.495 16.1514C69.9329 16.1514 71.3936 17.9378 71.3936 19.9449L71.3883 19.9501ZM65.1569 19.9291C65.1569 21.3793 65.945 22.5825 67.5055 22.5825C69.066 22.5825 69.7805 21.3005 69.7805 19.9711C69.7805 18.6418 69.0397 17.3703 67.4372 17.3703C65.8346 17.3703 65.1569 18.5893 65.1569 19.9344V19.9291Z'
          fill='#F43D4B'
        />
        <path
          d='M75.6707 20.7016V23.6386H74.1575V16.3143H77.9825C79.606 16.3143 80.5886 17.0972 80.5886 18.3687C80.5886 19.2934 80.0737 19.8767 79.2908 20.1078C79.6533 20.2339 80.3994 20.5439 80.3994 21.8522V22.2043C80.3994 22.7244 80.3942 23.3076 80.4887 23.6439H78.923C78.8074 23.3234 78.8022 22.8453 78.8022 22.1254V21.8838C78.8022 21.0273 78.4817 20.7068 77.3415 20.7068H75.6759L75.6707 20.7016ZM75.6707 19.5351H77.6305C78.5867 19.5351 79.0281 19.1936 79.0281 18.4896C79.0281 17.838 78.5815 17.4808 77.6567 17.4808H75.6759V19.5351H75.6707Z'
          fill='#F43D4B'
        />
        <path
          d='M83.4363 16.3092H84.96V19.6509C86.0371 18.6841 87.3191 17.5702 88.7115 16.3092H90.603L87.0669 19.4565L90.8079 23.6335H88.8901L85.9635 20.2919L84.96 21.1168V23.6388H83.4363V16.3145V16.3092Z'
          fill='#F43D4B'
        />
        <path
          d='M30.6109 16.3353V16.3196H29.208V16.3353H29.2027V21.6999C28.7194 20.8382 27.4058 19.0885 25.5458 16.3196H23.7332V23.6439H23.7437H25.1465V17.9116C25.6982 18.8731 28.1099 22.4565 28.919 23.6386H30.6214V22.8032V16.3301L30.6109 16.3353Z'
          fill='#F43D4B'
        />
        <path
          d='M39.2381 22.3988H35.2923V20.439H39.2381V19.199H35.2923V17.5492H39.2381V16.3092H33.7686V23.6335H39.2381V22.3988Z'
          fill='#F43D4B'
        />
      </g>
      <defs>
        <clipPath id='clip0_878_195'>
          <rect width='90.8078' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RedTextXA;
