export enum QUERY_KEY {
  LOGIN_LINKEDIN_REDIRECT = 'LOGIN_LINKEDIN_REDIRECT',
  GET_LINKEDIN_PROFILE = 'GET_LINKEDIN_PROFILE',
  GET_LINKEDIN_ACCESS_TOKEN = 'GET_LINKEDIN_ACCESS_TOKEN',
  GET_MY_PROFILE = 'GET_MY_PROFILE',
  GET_MEMBER_PROFILE = 'GET_MEMBER_PROFILE',
  GET_MEMBER_NOTABLE_INVESTMENTS = 'GET_MEMBER_NOTABLE_INVESTMENTS',
  GET_MEMBER_INVESTMENTS = 'GET_MEMBER_INVESTMENTS',
  GET_DASHBOARD_PIPELINES = 'GET_DASHBOARD_PIPELINES',
  GET_INVESTMENTS_CURRENT_VALUE = 'GET_INVESTMENTS_CURRENT_VALUE',
}
