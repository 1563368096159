import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface TableState {
  globalTableSearchText: string;
  setGlobalSearchText: (searchText: string) => void;
}

export const useTableStore = create<TableState>()(
  devtools(
    (set) => ({
      globalTableSearchText: '',
      setGlobalSearchText: (searchText) =>
        set({ globalTableSearchText: searchText }, false, 'setSearchText'),
    }),
    { name: 'TableStore' },
  ),
);
