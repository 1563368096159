import { createTheme } from '@mantine/core';

export const theme = createTheme({
  /* Put your mantine theme override here */
  defaultRadius: 'md',
  colors: {
    red: [
      '#FFE0E4', // Lightest
      '#FFCED2',
      '#FFA6B0',
      '#FF7D8D',
      '#FF556B',
      '#FF2D49',
      '#F43D4B', //
      '#D32F41',
      '#AD283A',
      '#872134',
    ],
    white: [
      '#FFFFFF', // Lightest
      '#FAFAFA', // Light
      '#F5F5F5', // Medium
      '#E0E0E0', // Dark
      '#D9D9D9', // Darkest
      '#C4C4C4', //
      '#BDBDBD', //
      '#ADADAD', //
      '#A3A3A3', //
      '#999999', //
    ],
  },
});
