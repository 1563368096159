import { useSideBarStore } from '@store/useSideBarStore';
import { AnimatePresence } from 'framer-motion';
import CollapsedSidebar from './CollapsedSidebar';
import ExpandedSidebar from './ExpandedSidebar';

const SidebarAnimatedWrapper = () => {
  const isExpanded = useSideBarStore((state) => state.isExpanded);
  return (
    <>
      <AnimatePresence mode='wait' initial={true}>
        {isExpanded ? <ExpandedSidebar /> : <CollapsedSidebar />}
      </AnimatePresence>
    </>
  );
};

export default SidebarAnimatedWrapper;
