import { SVGProps } from 'react';

const LifeJacket = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.395 7.395L13.755 13.755M22.245 22.245L28.605 28.605M28.605 7.395L22.245 13.755L27.54 8.46M7.395 28.605L13.755 22.245M33 18C33 26.2843 26.2843 33 18 33C9.71573 33 3 26.2843 3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18ZM24 18C24 21.3137 21.3137 24 18 24C14.6863 24 12 21.3137 12 18C12 14.6863 14.6863 12 18 12C21.3137 12 24 14.6863 24 18Z'
        stroke={props.color || '#FFF'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default LifeJacket;
