import * as React from 'react';

export interface RootContext {
  pageTopRef: React.RefObject<HTMLDivElement>;
}
const RootContext = React.createContext<RootContext>({
  pageTopRef: { current: null },
});
export function AppProvider({ children }: { children: React.ReactNode }) {
  const pageTopRef = React.useRef<HTMLDivElement>(null);

  const value = React.useMemo(
    () => ({
      pageTopRef,
    }),
    [pageTopRef],
  );

  return <RootContext.Provider value={value}>{children}</RootContext.Provider>;
}

export function useRootContext() {
  const context = React.useContext(RootContext);
  if (!context) {
    throw new Error('useRootContext must be used within an RootProvider');
  }
  return context;
}
