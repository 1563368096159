import { SCREEN_SIZE } from '@constants/enums';
import { useWindowSize } from '@hooks/useWindowSize';
import { Paper, Group, Button, Stepper, Text } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

interface UserNotFoundProps {
  onBack: () => void;
}

const UserNotFound = ({ onBack }: UserNotFoundProps) => {
  const { width } = useWindowSize();
  const isMobile = width < SCREEN_SIZE.LG;

  return (
    <div className='flex flex-col'>
      <Paper radius='md' p='xl' withBorder className='relative ' pos={'relative'}>
        <Text size='xl' fw={600} className='mb-4 text-center'>
          User with provided email not found, please contact XA support:
        </Text>
        <Group grow mb='md' mt='md'>
          <Button
            variant='filled'
            onClick={() => {
              window.open('mailto:memberops@xanetwork.co', `${isMobile ? '_self' : '_blank'}`);
            }}
          >
            Contact XA Support
          </Button>
        </Group>
        <Stepper active={1}>
          <Stepper.Step label='Step 1' description='Contact XA Support' loading />
          <Stepper.Step label='Step 2' description='Get email verifed' />
          <Stepper.Step label='Step 3' description='Get full access' />
        </Stepper>
      </Paper>
      <Button className='self-end mt-2' onClick={onBack}>
        <IconArrowLeft /> Back to login
      </Button>
    </div>
  );
};

export default UserNotFound;
