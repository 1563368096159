import { NormalLink } from '@constants/nav';
import { Menu } from '@mantine/core';
import { Link } from '@tanstack/react-router';
import { cn } from '@utils/utils';
import { HierachyLine } from '../NavBars/ExpandNavLink';

type HamburgerNormalLinkProps = {
  className?: string;
  showHierachyLine?: boolean;
  onClick?: () => void;
} & NormalLink;

const HamburgerNormalLink = ({
  showHierachyLine = false,
  icon: Icon,
  label,
  link,
  className,
  onClick,
}: HamburgerNormalLinkProps) => {
  return (
    <Link
      to={link}
      className='group text-unactive-link'
      activeProps={{ className: '!text-white' }}
      onClick={onClick}
    >
      <Menu.Item
        className={cn(
          'relative text-current px-5 py-3 sm:py-4 bg-primary-black text-xl group-hover:text-white group-hover:bg-dark-red group-active:text-white group-active:bg-dark-red',
          className,
        )}
        component='div'
        classNames={{
          itemLabel: 'flex flex-row items-center gap-4',
        }}
      >
        {Icon && <Icon stroke={1.5} width={26} height={26} className='shrink-0' />}
        <span>{label}</span>

        {showHierachyLine && <HierachyLine />}
      </Menu.Item>
    </Link>
  );
};

export default HamburgerNormalLink;
