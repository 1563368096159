import { QUERY_KEY } from '@constants/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { axiosClient } from '../../../axios/axiosClient';
import { Investment } from '../../../types/InvestmentTable/type';

//not used
export function useGetMyInvestments() {
  return useQuery({
    queryKey: ['Get_My_Investments'],
    queryFn: async () => {
      const response = await axiosClient.get('/my-investments');
      return response.data;
    },
    initialData: {
      allInvestments: {},
      allCompanies: {},
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetInvestmentByXAMemberId(userRecordId?: string, type?: string) {
  return useQuery({
    queryKey: [QUERY_KEY.GET_MEMBER_INVESTMENTS, userRecordId],
    queryFn: async () => {
      const response = await axiosClient.get('/investment/' + userRecordId, {
        params: {
          type,
        },
      });
      return response.data as Investment[];
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!userRecordId,
  });
}
