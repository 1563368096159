import AuthenticatedLayout from '@layouts/AuthenticatedLayout';
import MainLayout from '@layouts/Main';
import PublicLayout from '@layouts/PublicLayout';
import { useRootContext } from '@provider/AppContext';
import { AuthContext, useAuth } from '@provider/AuthContext';
import { useSideBarStore } from '@store/useSideBarStore';
import { Outlet, createRootRouteWithContext, useLocation } from '@tanstack/react-router';
import { useEffect, useMemo } from 'react';

type MyRouterContext = {
  authentication: AuthContext;
};
export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: RootComponent,
});

export function RootComponent() {
  const isExpanded = useSideBarStore((state) => state.isExpanded);
  const { pageTopRef } = useRootContext();
  const { user } = useAuth();
  const { pathname } = useLocation();

  const isNoPaddingPage = useMemo(() => {
    const investmentDetailPath = new RegExp(/^\/home\/.*/);

    return investmentDetailPath.test(pathname) || pathname === '/my-profile';
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!user)
    return (
      <PublicLayout isExpanded={isExpanded}>
        <Outlet />
      </PublicLayout>
    );

  return (
    <>
      <AuthenticatedLayout isExpanded={isExpanded}>
        <div className='flex-1 bg-var(--mantine-color-white)'>
          <MainLayout ref={pageTopRef} isNoPaddingPage={isNoPaddingPage}>
            <Outlet />
          </MainLayout>
        </div>
      </AuthenticatedLayout>
    </>
  );
}
