import { createFormContext } from '@mantine/form';

export interface MyPortfolioTableFilterFormValues {
  visibleColumns: string[];
}

const [PortfolioFilterFormProvider, usePortfolioFormContext, usePortfolioFilterForm] =
  createFormContext<MyPortfolioTableFilterFormValues>();

export { PortfolioFilterFormProvider, usePortfolioFormContext, usePortfolioFilterForm };
