import { SCREEN_SIZE } from '@constants/enums';
import { useWindowSize } from '@hooks/useWindowSize';
import { Tooltip } from '@mantine/core';
import { useAllMembers } from '@queries/XA Members/useXaMembersQuery';
import { useNavigate, useRouterState } from '@tanstack/react-router';
import { NullableType } from '@type/common';
import { cn } from '@utils/utils';

interface XaLeadColumnProps {
  leaderId: string;
  leaderName: NullableType<string>;
  leaderLinkedIn: string;
}

const XaLeadColumn = ({ leaderId, leaderName, leaderLinkedIn }: XaLeadColumnProps) => {
  const navigate = useNavigate({});
  const router = useRouterState();
  const { width } = useWindowSize();
  const isMobile = width < SCREEN_SIZE.LG;
  const isSamePage = router.location.pathname.split('/')[2] === leaderId;

  const { data } = useAllMembers();
  const members = data?.recordsById || {};

  return (
    <div
      className={cn('flex flex-col text-wrap cursor-pointer', {
        'items-center flex-row': !members[leaderId],
        'cursor-not-allowed': isSamePage,
      })}
    >
      <span
        onClick={() => {
          if (isSamePage) return;
          if (members[leaderId]) {
            navigate({
              to: `/members/$memberId`,
              params: {
                memberId: leaderId,
              },
              viewTransition: true,
            });
            return;
          }
          if (leaderLinkedIn) {
            window.open(leaderLinkedIn, `${isMobile ? '_self' : '_blank'}`);
            return;
          }
        }}
      >
        <Tooltip label={members[leaderId] ? 'View Member' : 'View member Linkedin profile'}>
          <span
            className={cn('font-sans font-normal text-[16px] leading-[24px] overflow-ellipsis', {
              'hover:underline': !members[leaderId],
            })}
          >
            {leaderName}
          </span>
        </Tooltip>
      </span>
    </div>
  );
};

export default XaLeadColumn;
