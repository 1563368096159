import SvgXa from '@assets/icons/Xa';
import { UserButton } from '@components/Buttons/UserButton/UserButton';
import { NAV_LINKS } from '@constants/nav';
import { Group, ScrollArea } from '@mantine/core';
import { useAuth } from '@provider/AuthContext';
import { Link } from '@tanstack/react-router';
import { LinksGroupExpand } from '../NavbarLinksGroup/NavbarLinksGroup';

const NavbarExpand = () => {
  const { user } = useAuth();
  const navLinks = NAV_LINKS.map((item) => <LinksGroupExpand {...item} key={item.id} />);

  return (
    <nav className='bg-primary-black h-full flex flex-col pt-4 pb-0 w-fit'>
      <div className='border-b border-divider p-4 pt-0'>
        <Group className='justify-between'>
          <Link to={'/'} className='flex items-center'>
            <SvgXa />
            <span className='text-xl font-semibold -translate-x-1 tracking-[0.16em] font-sans text-darkest-red'>
              NETWORK
            </span>
          </Link>
        </Group>
      </div>

      <ScrollArea className='flex-1'>
        <div className='py-8 px-5 flex flex-col gap-4'>{navLinks}</div>
      </ScrollArea>

      <div className='border-t border-divider'>
        <UserButton user={user} />
      </div>
    </nav>
  );
};

export default NavbarExpand;
