import { Tooltip, Button, rem } from '@mantine/core';
import { IconHome2 } from '@tabler/icons-react';
import React from 'react';

interface ToolTipButtonProps {
  icon: typeof IconHome2;
  label: string;
  active?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const TooltipButton = ({ icon: Icon, label, active, className, onClick }: ToolTipButtonProps) => {
  return (
    <Tooltip label={label} className={className} position='right' transitionProps={{ duration: 0 }}>
      <Button variant='subtle' onClick={onClick} data-active={active || undefined}>
        <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
      </Button>
    </Tooltip>
  );
};

export default TooltipButton;
