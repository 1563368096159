import SvgFilter from '@assets/icons/Filter';
import { Button, CloseButton, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { createFileRoute } from '@tanstack/react-router';
import ReactGA from 'react-ga4';
import tw from 'tailwind-styled-components';
import { InputWithButton } from '../../../components/pages/members/components/InputWithButton';
import { useAuth } from '../../../providers/AuthContext';
import { useTableStore } from '../../../store/useTableStore';
import { InvesmentTable } from '../../../tables/InvesmentTable';
import { useGetInvestmentByXAMemberId } from '../../../useQueries/bases/XA Network Pipeline (Duplicate for Platform Build)/useXaNetworkPipeline';

export const Route = createFileRoute('/_authenticated/(my-portfolio)/my-portfolio')({
  component: () => <MyPortfolio />,
});

const MyPortfolioContainer = tw.div`relative h-full bg-secondary-gray rounded-3xl p-5 md:p-8 lg:p-12 flex flex-col`;

function MyPortfolio() {
  ReactGA.send({ hitType: 'pageview', page: '/my-portfolio', title: 'My Portfolio Page' });

  const { user } = useAuth();
  const [globalSearchText, setGlobalSearchText] = useTableStore((state) => [
    state.globalTableSearchText,
    state.setGlobalSearchText,
  ]);
  const [opened, { close, toggle }] = useDisclosure(false);
  const {
    data: memberInvestment = [],
    isFetched,
    isFetching,
  } = useGetInvestmentByXAMemberId(user?.['record id'], 'my-portfolio');

  if (isFetching) {
    return (
      <MyPortfolioContainer className='relative h-full'>
        <LoadingOverlay loaderProps={{ color: 'red' }} visible={isFetching} className='z-header' />
      </MyPortfolioContainer>
    );
  }

  const handleClearText = () => {
    setGlobalSearchText('');
  };

  return (
    <MyPortfolioContainer>
      <h2 className='font-Roobert relativ e font-semibold text-2xl leading-8 lg:text-[40px] lg:leading-[38px] mb-3 lg:mb-4 items-center gap-2 flex'>
        My Portfolio
      </h2>
      <h2 className='font-sans font-normal text-base leading-6 w-full mb-8 lg:mb-9'>
        View your investments and details.
      </h2>

      {isFetched && memberInvestment.length > 0 && (
        <div className='flex flex-col flex-1'>
          <div className='relative flex gap-3 justify-between items-center'>
            <Button className='bg-white py-2.5 px-4 h-full lg:hidden' onClick={toggle}>
              <SvgFilter />
            </Button>
            <InputWithButton
              className='items-center flex-1 lg:flex-none'
              onChange={(e) => {
                setGlobalSearchText(e.target.value);
              }}
              value={globalSearchText}
              rightSection={
                globalSearchText ? (
                  <CloseButton aria-label='Clear input' onClick={handleClearText} />
                ) : (
                  <></>
                )
              }
            />
          </div>
          {user && (
            <div className='mt-4 flex-1'>
              <InvesmentTable
                userRecordId={user['record id']}
                type='my-portfolio'
                isModalOpen={opened}
                onCloseModal={close}
              />
            </div>
          )}
        </div>
      )}
    </MyPortfolioContainer>
  );
}
