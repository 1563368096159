import { SVGProps } from 'react';

const Xa = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='48'
      height='77'
      viewBox='0 0 48 77'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_430_5635)'>
        <path
          d='M26.9702 31.0635L23.8588 26.2614H23.8388L20.6474 31.0635H16.9258L21.938 24.0204L17.0658 17.0674H21.0776L24.0089 21.5894H24.0289L27.0702 17.0674H30.7218L25.8797 23.7303L30.9619 31.0735H26.9702V31.0635Z'
          fill='#fff'
        />
        <path
          d='M21.2084 55.9544L20.1479 59.0257H16.9165L21.7586 45.1196H25.9004L30.9527 59.0257H27.5012L26.3807 55.9544H21.2084ZM25.6804 53.3532C24.7099 50.532 24.0796 48.6612 23.7295 47.3806H23.7095C23.3593 48.7812 22.659 50.9422 21.8787 53.3532H25.6804Z'
          fill='#fff'
        />
        <path
          d='M45.1193 59.1748V73.201H2.89078V59.1748H-0.000488281V76.1623H48.0006V59.1748H45.1193Z'
          fill='#fff'
        />
        <path
          d='M2.89078 16.9875V2.9613H45.1193V16.9875H48.0006V0H-0.000488281V16.9875H2.89078Z'
          fill='#fff'
        />
      </g>
      <defs>
        <clipPath id='clip0_430_5635'>
          <rect width='48.0011' height='77' fill='white' transform='translate(-0.000488281)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Xa;
