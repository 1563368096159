import { ActionIcon, Anchor, Box, Button, Divider, ScrollArea, Tabs } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconX } from '@tabler/icons-react';
import { ChildFormFilterConfig, FormFilterConfig } from '@type/InvestmentTable/type';
import { cn } from '@utils/utils';
import { motion } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import CheckboxGroupFilter from './CheckboxGroup';
import RadioGroupFilter from './RadioGroup';

const sidebar = {
  open: () => ({
    opacity: 1,
    x: 0,
    transition: {
      type: 'tween',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    x: '100%',
    opacity: 0,
    transition: {
      delay: 0.1,
      type: 'tween',
      stiffness: 40,
      damping: 40,
    },
  },
};

interface TableFiltersModalProps {
  isOpen: boolean;
  onClose?: () => void;
  formsConfig: FormFilterConfig<any>;
  formInstance: UseFormReturnType<any>;
  defaultFilterOptions?: Record<string, string[]>;
  onCustomReset?: () => void;
}

const TableFiltersModal = ({
  isOpen,
  formsConfig,
  formInstance,
  defaultFilterOptions,
  onClose,
  onCustomReset,
}: TableFiltersModalProps) => {
  const { reset, values: defaultValues, setValues } = formInstance;

  const defaultOptions = useMemo(() => {
    return defaultFilterOptions || defaultValues;
  }, [defaultFilterOptions, defaultValues]);

  useEffect(() => {
    return () => {
      onClose && onClose();
    };
  }, [onClose]);

  const handleReset = () => {
    reset();
    defaultFilterOptions ? setValues(defaultFilterOptions) : setValues(defaultValues);
    onCustomReset && onCustomReset();
  };

  const renderSubFormByType = (form: ChildFormFilterConfig<any>) => {
    switch (form.type) {
      case 'checkbox':
        return (
          <div className='py-2 px-3' id={form.name}>
            <CheckboxGroupFilter
              name={form.name}
              defaultOptions={defaultOptions[form.name as any]}
              formInstance={formInstance}
              title={`Filter by ${form.label}`}
            />
          </div>
        );
      default:
        return (
          <div className='py-2 px-3' id={form.name}>
            <RadioGroupFilter
              name={form.name}
              defaultOptions={defaultOptions[form.name as any]}
              formInstance={formInstance}
              title={`${form.label}`}
            />
          </div>
        );
    }
  };

  return (
    <motion.aside
      initial={{
        x: 0,
        opacity: 0,
      }}
      animate={isOpen ? 'open' : 'closed'}
      variants={sidebar}
      className={cn(
        'font-sans fixed text-base inset-x-0 top-0 text-primary-black bg-white h-dvh z-modal',
      )}
    >
      <div className='text-white flex h-full flex-col justify-between py-4 px-2 '>
        <ActionIcon color='red' onClick={onClose} className='ml-auto' variant='transparent'>
          <IconX />
        </ActionIcon>

        <Tabs
          defaultValue='gallery'
          color='red'
          classNames={{ tabLabel: 'text-dark-red text-sm font-bold' }}
        >
          <ScrollArea type='auto' className='scroll-smooth' scrollbars='x'>
            <Tabs.List className='flex items-center flex-nowrap mb-4'>
              {formsConfig.forms.map((form) => (
                <Anchor href={`#${form.name}`} key={form.name}>
                  <Tabs.Tab value={form.name}>{form.label}</Tabs.Tab>
                </Anchor>
              ))}
            </Tabs.List>
          </ScrollArea>
        </Tabs>
        <div className='scroll-smooth overflow-auto text-primary-black flex-1'>
          {formsConfig.forms.map((form) => (
            <Box key={form.name}>
              {renderSubFormByType(form)}
              <Divider className='border-gray my-4' />
            </Box>
          ))}
        </div>
        <div className='flex flex-col gap-3 py-5 bottom-0 inset-x-0 bg-white shrink-0'>
          <Button
            color='gray'
            size='md'
            type='button'
            onClick={handleReset}
            variant='outline'
            className='mx-3 text-tetiary-black font-sans'
          >
            Reset
          </Button>
          <Button
            color='red'
            size='md'
            type='submit'
            className='font-sans mx-3 border-current border'
          >
            Apply Filters
          </Button>
        </div>
      </div>
    </motion.aside>
  );
};

export default TableFiltersModal;
