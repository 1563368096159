import { ExpandLink, NavLinkItemType } from '@constants/nav';
import { Collapse, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useLocation } from '@tanstack/react-router';
import { cn } from '@utils/utils';
import HamburgerNormalLink from './HamburgerNormalLink';

type HamburgerNormalLinkProps = { className?: string; onClick?: () => void } & ExpandLink;

const HamburgerExpandLink = ({
  links,
  label,
  icon: Icon,
  expandIcon: ExpandIcon,
  className,
  onClick,
}: HamburgerNormalLinkProps) => {
  const { pathname } = useLocation();
  const [opened, { toggle }] = useDisclosure(false);

  const isActive = links.some(({ link }) => link === pathname);
  return (
    <div>
      <Menu.Item
        className={cn(
          'bg-primary-black px-5 py-3 sm:py-4 text-unactive-link text-xl active:bg-dark-red active:text-white',
          className,
          {
            'text-white': isActive,
          },
        )}
        color='white'
        component='div'
        onClick={toggle}
        classNames={{
          itemLabel: 'flex flex-row items-center gap-4',
        }}
      >
        {Icon && <Icon stroke={1.5} width={26} height={26} className='shrink-0' />}
        <div className='flex justify-between items-center flex-1'>
          <span>{label}</span>
          {ExpandIcon && (
            <ExpandIcon
              stroke={1.5}
              width={20}
              height={20}
              className={cn('shrink-0 transition duration-300', { 'rotate-90': opened })}
            />
          )}
        </div>
      </Menu.Item>
      <Collapse in={opened} className='ml-10'>
        {links.map((link) => (
          <HamburgerNormalLink
            key={link.link}
            {...link}
            type={NavLinkItemType.NORMAL}
            className='text-base py-2'
            onClick={onClick}
          />
        ))}
      </Collapse>
    </div>
  );
};

export default HamburgerExpandLink;
