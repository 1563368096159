import { createFileRoute, redirect } from '@tanstack/react-router';
import isEmpty from 'lodash/isEmpty';
import toast from 'react-hot-toast';

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: async ({ context }) => {
    const userAuth = context.authentication?.user?.jwt;
    if (!userAuth || isEmpty(userAuth?.trim())) {
      throw redirect({ to: '/login' });
    }

    try {
      const decodedJwt = JSON.parse(atob(userAuth.split('.')[1]));
      if (decodedJwt.exp * 1000 < Date.now()) {
        const { setUser } = context.authentication;
        localStorage.removeItem('xa-network');
        setUser(null);
        showRedirectToast(3);
        throw redirect({ to: '/login' });
      }
    } catch (error) {
      console.error('Error', error);
    }
  },
});

function showRedirectToast(count: number) {
  let toastId: any = null;
  const interval = setInterval(() => {
    if (toastId === null) {
      toastId = toast.error(`Session expired, please login again. Redirecting in ${count}`);
    } else {
      toast.error(`Session expired, please login again. Redirecting in ${count}`, {
        id: toastId,
      });
    }

    count--;

    if (count === 0) {
      clearInterval(interval);
    }
  }, 1000);
}
