import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
interface SideBarState {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;

  isApplicationsFormOpen: boolean;
  setIsApplicationsFormOpen: (isApplicationsFormOpen: boolean) => void;
}

export const useSideBarStore = create<SideBarState>()(
  devtools(
    (set) => ({
      isExpanded: false,
      setIsExpanded: (isExpanded) => set({ isExpanded: isExpanded }, false, 'setIsExpanded'),

      isApplicationsFormOpen: false,
      setIsApplicationsFormOpen: (isApplicationsFormOpen) =>
        set({ isApplicationsFormOpen: isApplicationsFormOpen }, false, 'setIsApplicationsFormOpen'),
    }),

    { name: 'SideBarStore' },
  ),
);
