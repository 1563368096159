import { cn } from '@utils/utils';
import React, { forwardRef } from 'react';
import tw from 'tailwind-styled-components';

const MainLayoutContainer = tw.div`
  p-5 text-[#1A1A1B] h-full
  lg:p-10
`;

type MainLayoutProps = { children: React.ReactNode; className?: string; isNoPaddingPage?: boolean };

const MainLayout = forwardRef<HTMLDivElement, MainLayoutProps>(
  ({ children, className, isNoPaddingPage = false }, ref) => {
    return (
      <MainLayoutContainer
        ref={ref}
        className={cn(className, {
          'p-0 lg:p-0': isNoPaddingPage,
        })}
      >
        {children}
      </MainLayoutContainer>
    );
  },
);

export default MainLayout;
