import { XA_NETWORK_PRIVACY_LINK } from '@constants/links';
import { Group, Anchor } from '@mantine/core';
import dayjs from 'dayjs';

const FOOTER_LINKS = [
  { href: '/terms', label: 'Terms', type: 'internal' },
  { href: XA_NETWORK_PRIVACY_LINK, label: 'Privacy', type: 'external' },
];

export function Footer() {
  const items = FOOTER_LINKS.map((link) => (
    <Anchor<'a'>
      c='dimmed'
      key={link.label}
      href={link.href}
      target={link.type === 'internal' ? '_self' : '_blank'}
      size='sm'
    >
      {link.label}
    </Anchor>
  ));

  return (
    <div className='px-2 xs:px-4 sm:px-6 lg:px-8 w-full text-high-medium-gray bg-white'>
      <div className='font-sans font-normal gap-2 flex h-full w-full justify-between items-center py-3 sm:py-4 lg:py-5 border-t border-t-gray flex-col lg:flex-row'>
        <span className='text-sm phone-lg:text-base'>
          © Copyright {dayjs().year()} XA Pte. Ltd. All rights reserved.
        </span>
        <Group justify='center' gap='md'>
          {items}
        </Group>
      </div>
    </div>
  );
}
