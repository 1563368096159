import { useAuth } from '@provider/AuthContext';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';

const useLogout = () => {
  const { setUser } = useAuth();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    setUser(null);
    localStorage.clear();
    navigate({
      to: '/',
      replace: true,
      resetScroll: true,
    });
  }, [navigate, setUser]);

  return { handleLogout };
};

export default useLogout;
