import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function normalizeUserData(data: any) {
  // Normalize keys to lowercase
  return Object.keys(data).reduce((acc: any, key: string) => {
    acc[key.toLowerCase()] = data[key];
    return acc;
  }, {});
}
