import { Tooltip } from '@mantine/core';
import { IconArrowUp, IconArrowDown, IconArrowsSort } from '@tabler/icons-react';
import { flexRender, Table } from '@tanstack/react-table';
import { cn } from '@utils/utils';
import TablePagination from './TablePagination';
import TablePaginationPageSize from './TablePaginationPageSize';

interface TableViewProps {
  tableInstance: Table<any>;
  className?: string;
  headClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  paginationConfig?: TablePaginationConfig;
}

type TablePaginationConfig = {
  pageSize: boolean;
  pageIndex: boolean;
};

const TableView = ({
  tableInstance,
  className,
  headClassName,
  bodyClassName,
  rowClassName,
  paginationConfig,
}: TableViewProps) => {
  const rowsData = tableInstance.getRowModel().rows;

  if (rowsData.length === 0) {
    return (
      <div className='w-full h-full flex items-center justify-center text-xl md:text-2xl'>
        No Result Found
      </div>
    );
  }

  return (
    <>
      <table className={cn('min-w-full divide-y divide-gray-200', className)}>
        <thead className={cn('bg-gray-300', headClassName)}>
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} className='relative'>
                    {header.isPlaceholder ? null : (
                      <div
                        className={cn(
                          'p-2 lg:px-4 lg:py-3 text-sm font-medium lg:tracking-wider text-left text-gray-500 uppercase flex items-center justify-between gap-2',
                          {
                            'cursor-pointer select-none': header.column.getCanSort(),
                          },
                        )}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: (
                            <Tooltip label='Sort ascending'>
                              <IconArrowUp size={14} stroke={2} className='hover:text-green-800' />
                            </Tooltip>
                          ),
                          desc: (
                            <Tooltip label='Sort descending'>
                              <IconArrowDown
                                size={14}
                                stroke={2}
                                className='hover:text-green-800'
                              />
                            </Tooltip>
                          ),
                        }[header.column.getIsSorted() as string] ?? (
                          <>
                            {header.column.getCanSort() ? (
                              <Tooltip label='Sort'>
                                <IconArrowsSort
                                  size={14}
                                  stroke={2}
                                  className='hover:text-green-800'
                                />
                              </Tooltip>
                            ) : null}
                          </>
                        )}
                        {header.column.getCanResize() && (
                          <span
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                            className={cn(
                              'absolute right-0 top-0 bottom-0 w-[2px] cursor-col-resize bg-gray-400 hover:w-[4px] hover:bg-gray-500 active:w-[4px] active:bg-gray-500',
                              {},
                            )}
                          ></span>
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className={cn('bg-white divide-y divide-gray-200', bodyClassName)}>
          {rowsData.map((row) => {
            const isVentureFund = row.original.company?.['Sector']?.includes('VC');

            return (
              <tr key={row.id} className={cn({ 'bg-medium-gray': !!isVentureFund }, rowClassName)}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className='p-2 lg:px-4 lg:py-2 text-left !max-w-[250px] !min-w-[250px] lg:!max-w-fit lg:!min-w-fit'
                      style={{
                        width: cell.column.getSize(),
                        minWidth: cell.column.getSize() ? cell.column.getSize() : 'auto',
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {paginationConfig && (
        <div className='flex flex-col lg:flex-row justify-between p-14 lg:py-8 bg-white rounded-b-2xl'>
          <TablePaginationPageSize
            onChange={tableInstance.setPageSize}
            currentPageSize={tableInstance.getState().pagination.pageSize}
            totalItems={tableInstance.getFilteredRowModel().rows.length}
          />
          <div className='flex absolute bottom-14 left-0 right-0 lg:static lg:translate-x-0'>
            <TablePagination
              total={tableInstance.getPageCount()}
              page={tableInstance.getState().pagination.pageIndex + 1}
              onChange={(value) => {
                const page = value ? Number(value) - 1 : 0;
                tableInstance.setPageIndex(page);
              }}
              className='w-full flex items-center justify-center px-3'
              classNames={{
                control: 'border-none flex items-center justify-center',
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TableView;
