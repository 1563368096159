import { SVGProps } from 'react';

const Filter = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='20'
      height='18'
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3.33331 16.6907V10.8573M3.33331 7.52401V1.69067M9.99998 16.6907V9.19067M9.99998 5.85734V1.69067M16.6666 16.6907V12.524M16.6666 9.19067V1.69067M0.833313 10.8573H5.83331M7.49998 5.85734H12.5M14.1666 12.524H19.1666'
        stroke='#484849'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Filter;
