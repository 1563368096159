import SvgRedTextXa from '@assets/icons/RedTextXa';
import { useDisclosure } from '@mantine/hooks';
import { Link } from '@tanstack/react-router';
import HamburgerMenu from './HamburgerMenu';
import HamburgerMenuDropdown from './HamburgerMenuDropdown';

const MobileHeader = () => {
  const [opened, { toggle }] = useDisclosure();

  return (
    <header className='flex justify-between px-5 items-center fixed h-[72px] bg-primary-black top-0 inset-x-0 z-modal'>
      <Link to='/home'>
        <SvgRedTextXa />
      </Link>
      <HamburgerMenu onToggle={toggle} isOpen={opened} />
      <HamburgerMenuDropdown onToggle={toggle} isOpen={opened} />
    </header>
  );
};

export default MobileHeader;
