import { useMutation } from '@tanstack/react-query';
import { axiosClient } from '../axios/axiosClient';

export interface LoginData {
  email: string;
  password: string;
}

const handleLogin = async (data: LoginData) => {
  try {
    const response = await axiosClient.post('/auth/login', data);
    return response;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export function useLogin() {
  return useMutation({
    mutationKey: ['Login_Normal'],
    mutationFn: handleLogin,
  });
}

export function useLoginWithLinkedin() {
  return useMutation({
    mutationKey: ['Login_With_LinkedIn'],
    mutationFn: async (data: any) => {
      const response = await axiosClient.post('/auth/login/linkedin', data);
      return response;
    },
  });
}

export function useSignUp(onSuccess?: () => void, onError?: () => void) {
  return useMutation({
    mutationKey: ['SignUp_Normal'],
    mutationFn: async (data: any) => {
      const response = await axiosClient.post('/auth/signup', data);
      return response;
    },
    onSuccess: onSuccess,
    onError: onError,
  });
}
