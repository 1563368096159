import { Footer } from '@components/Base/Footer/Footer';
import { cn } from '@utils/utils';
import { PropsWithChildren } from 'react';

type PublicLayoutProps = {
  isExpanded: boolean;
};

const PublicLayout = ({ isExpanded, children }: PropsWithChildren<PublicLayoutProps>) => {
  return (
    <>
      <div
        className={cn(`h-full w-full grid grid-cols-[repeat(12,1fr)]`, {
          'grid-cols-[82px_repeat(12,1fr)]': !isExpanded,
        })}
      >
        <div className='flex flex-col col-start-1 col-span-full dark:bg-[var(--mantine-color-dark-6)] bg-var(--mantine-color-white)'>
          <div className='flex-1 bg-var(--mantine-color-white)'>{children}</div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicLayout;
