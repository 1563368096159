import { Menu, Button, rem } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { arrayRowsPerPage } from '@utils/helpers';

interface TablePaginationPageSizeProps {
  currentPageSize: number;
  onChange: (value: number) => void;
  totalItems: number;
}

const TablePaginationPageSize = ({
  currentPageSize,
  onChange,
  totalItems,
}: TablePaginationPageSizeProps) => {
  return (
    <div className='flex items-center gap-2 absolute left-4 bottom-4 lg:static'>
      <span>Show:</span>
      <Menu
        transitionProps={{ transition: 'pop-bottom-right' }}
        position='bottom-start'
        withinPortal
      >
        <Menu.Target>
          <Button
            variant='outline'
            className='shadow-md'
            rightSection={
              <IconChevronDown style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
            }
            pr={12}
          >
            {currentPageSize}
          </Button>
        </Menu.Target>
        <Menu.Dropdown w={'max-content'}>
          {arrayRowsPerPage({
            maxItemPerPage: currentPageSize,
            itemsCount: totalItems,
          }).map((pageSize) => {
            return (
              <Menu.Item
                w={'max-content'}
                key={pageSize}
                className='text-center px-6'
                value={pageSize}
                onClick={() => onChange(pageSize)}
              >
                {pageSize}
              </Menu.Item>
            );
          })}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default TablePaginationPageSize;
