import { Group, Avatar, Text } from '@mantine/core';
import { useAuth, User } from '@provider/AuthContext';
import { useGetUserProfile } from '@queries/XA Members/useXaMembersQuery';
import { IconLogout } from '@tabler/icons-react';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useEffect } from 'react';
import TooltipButton from './TooltipButton';

export function UserButton({ user }: { user: User | null }) {
  const { setUser } = useAuth();
  const navigate = useNavigate();

  const { data: memberProfile, refetch } = useGetUserProfile(user?.['record id'] || '');

  const handleLogout = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setUser(null);
      localStorage.clear();
      navigate({
        to: '/',
        replace: true,
        resetScroll: true,
      });
    },
    [navigate, setUser],
  );

  const handleGoToProfile = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      navigate({
        to: '/my-profile',
      });
    },
    [navigate],
  );

  useEffect(() => {
    if (!memberProfile) {
      refetch();
    }
  }, [user?.jwt, memberProfile, refetch]);

  return (
    <div className='flex items-center justify-between'>
      <div
        className='w-full p-4 text-white hover:bg-secondary-black'
        onClick={(e) => handleGoToProfile(e)}
      >
        <Group wrap='wrap'>
          <div className='relative'>
            <Avatar
              src={
                memberProfile?.fields?.['Profile Photo']?.[0]?.thumbnails?.full?.url ||
                user?.picture
              }
              radius='xl'
            />
          </div>

          <div className='cursor-pointer'>
            <Text size='sm' fw={500}>
              {user?.name || user?.['full name']}
            </Text>

            <Text c='dimmed' size='xs'>
              {user?.email}
            </Text>
          </div>
        </Group>
      </div>
      <TooltipButton
        className='mx-2'
        icon={IconLogout}
        label='Logout'
        onClick={(e) => handleLogout(e)}
      />
    </div>
  );
}
