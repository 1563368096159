import dayjs from 'dayjs';

interface InvestmentDateColumnProps {
  dates: string[];
}

const InvestmentDateColumn = ({ dates }: InvestmentDateColumnProps) => {
  if (!dates) {
    return <span>Not specified</span>;
  }
  const [date] = dates;
  const formattedDate = dayjs(date.toString()).format(` MMMM DD,  YYYY`);

  return <span>{formattedDate}</span>;
};

export default InvestmentDateColumn;
