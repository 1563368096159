import { ExpandLink, NavLink, NavLinkItemType, NormalLink } from '@constants/nav';
import {  Divider} from '@mantine/core';
import ExpandNavLink from '../ExpandNavLink';
import NormalNavLink from '../NormalNavLink';


export function LinksGroupExpand(props: NavLink) {
  const { id, type, ...rest } = props;

  return (
    <>
      {type === NavLinkItemType.NORMAL && <NormalNavLink {...(rest as NormalLink)} />}
      {type === NavLinkItemType.EXPAND && <ExpandNavLink {...(rest as ExpandLink)} />}
      {type === NavLinkItemType.DIVIDER && <Divider className='border-divider' />}
    </>
  );
}

export function LinksGroupCollapsed(props: NavLink) {


  const { id, type, ...rest } = props;

  return (
    <>
      {type === NavLinkItemType.NORMAL && <NormalNavLink {...(rest as NormalLink)} isCollapsed />}
      {type === NavLinkItemType.EXPAND && <ExpandNavLink {...(rest as ExpandLink)} isCollapsed />}
      {type === NavLinkItemType.DIVIDER && <Divider className='border-divider' />}
    </>
  );
}
