import { Pagination, Group, ClassNames } from '@mantine/core';

interface TablePaginationProps {
  onChange: (value: number) => void;
  onNext?: () => void;
  onBack?: () => void;
  total: number;
  page?: number;
  className?: string;
  classNames?: ClassNames<any>;
}

const TablePagination = ({
  onChange,
  total,
  page,
  className,
  classNames,
}: TablePaginationProps) => {
  return (
    <Pagination.Root
      total={total}
      value={page}
      onChange={onChange}
      className={className}
      classNames={classNames}
      color='red'
      radius='xl'
      autoContrast
    >
      <div className='flex items-center gap-1 text-dark-gray'>
        <Pagination.First className='hidden lg:flex' />
        <Pagination.Previous className='!bg-transparent' />
      </div>
      <Group className='w-full justify-center px-2 gap-2 overflow-auto lg:overflow-hidden flex-nowrap flex-1'>
        <Pagination.Items />
      </Group>
      <div className='flex items-center gap-2 text-dark-gray'>
        <Pagination.Next className='!bg-transparent' />
        <Pagination.Last className='hidden lg:flex' />
      </div>
    </Pagination.Root>
  );
};

export default TablePagination;
