import { Group } from '@mantine/core';
import { IconChevronsRight, IconChevronsLeft } from '@tabler/icons-react';
import { useSideBarStore } from '../../../store/useSideBarStore';
import BreadCrumb from '../Breadcrumb';

export function HeaderMegaMenu() {
  const [setIsExpanded, isExpanded] = useSideBarStore((state) => [
    state.setIsExpanded,
    state.isExpanded,
  ]);

  return (
    <div className='fixed top-0 left-0 right-0 z-header col-span-full'>
      <header className='sticky z-header bg-primary-black border-b border-divider px-4 h-[60px] top-0'>
        <div className='flex justify-between h-full'>
          <Group h='100%' gap={0} className='select-none'>
            {!isExpanded ? (
              <IconChevronsRight
                stroke={1.75}
                className='cursor-pointer text-darkest-red'
                onClick={() => {
                  setIsExpanded(true);
                }}
              />
            ) : (
              <IconChevronsLeft
                stroke={1.75}
                className='cursor-pointer text-darkest-red'
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              />
            )}
            <BreadCrumb itemClassName='mx-4 text-white' />
          </Group>
        </div>
      </header>
    </div>
  );
}
