import { NAV_LINKS, NavLink, NavLinkItemType } from '@constants/nav';
import useLogout from '@hooks/useLogout';
import { Divider, Menu } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { cn } from '@utils/utils';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import HamburgerCustomLink from './HamburgerCustomLink';
import HamburgerExpandLink from './HamburgerExpandLink';
import HamburgerNormalLink from './HamburgerNormalLink';

interface HamburgerMenuDropdownProps {
  isOpen: boolean;
  onToggle: () => void;
}

const sidebar = {
  open: () => ({
    opacity: 1,
    clipPath: `circle(200% at 100% 1%)`,
    transition: {
      type: 'tween',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(0% at 100% 0)',
    opacity: 0.5,
    transition: {
      delay: 0.1,
      type: 'tween',
      stiffness: 40,
      damping: 40,
    },
  },
};

const HamburgerMenuDropdown = ({ isOpen, onToggle }: HamburgerMenuDropdownProps) => {
  const { handleLogout } = useLogout();

  const mobileNavLinks: NavLink[] = useMemo(
    () => [
      ...NAV_LINKS,
      {
        id: 'divider-2',
        type: NavLinkItemType.DIVIDER,
      },
      {
        id: 'logout',
        type: NavLinkItemType.CUSTOM,
        label: 'Logout',
        icon: IconLogout,
      },
    ],
    [],
  );

  return (
    <motion.nav
      animate={isOpen ? 'open' : 'closed'}
      variants={sidebar}
      className={cn(
        'fixed overflow-y-scroll text-base p-4 inset-x-0 top-[72px] border text-primary-black border-current bg-primary-black h-screen z-modal',
        {
          absolute: !isOpen,
        },
      )}
    >
      <div className='text-white'>
        <Menu opened={true}>
          {mobileNavLinks.map((item) => {
            switch (item.type) {
              case NavLinkItemType.NORMAL:
                return <HamburgerNormalLink onClick={onToggle} key={item.id} {...item} />;
              case NavLinkItemType.EXPAND:
                return <HamburgerExpandLink onClick={onToggle} key={item.id} {...item} />;
              case NavLinkItemType.CUSTOM:
                return (
                  <HamburgerCustomLink
                    className='text-medium-dark-red'
                    key={item.id}
                    {...item}
                    onClick={() => {
                      onToggle();
                      handleLogout();
                    }}
                  />
                );
              default:
                return (
                  <Menu.Item className='px-5 py-3 sm:py-4' key={item.id} bg='transparent'>
                    <Divider className='border-divider' />
                  </Menu.Item>
                );
            }
          })}
        </Menu>
      </div>
    </motion.nav>
  );
};

export default HamburgerMenuDropdown;
