import { motion } from 'framer-motion';
import NavbarExpand from '../NavBars/NavbarExpand';

const ExpandedSidebar = () => {
  return (
    <motion.div
      initial={{ opacity: 0.8, x: -200 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 1 }}
      transition={{ duration: 0.2, ease: 'linear' }}
      className='fixed top-[60px] left-0 bottom-0'
    >
      <NavbarExpand />
    </motion.div>
  );
};

export default ExpandedSidebar;
