import { axiosClient } from '@api/axiosClient';
import { QUERY_KEY } from '@constants/queryKeys';
import { useQuery } from '@tanstack/react-query';

export function useGetLinkedinProfile(accessToken?: string) {
  const { data, isLoading, error } = useQuery({
    queryKey: [QUERY_KEY.GET_LINKEDIN_PROFILE, accessToken],
    queryFn: async () => {
      const response = await axiosClient.post<any>(`/auth/linkedin/profile`, {
        accessToken,
      });
      return response.data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!accessToken,
    retry: true,
    retryDelay: 1000,
  });
  return { linkedinProfile: data, isLoading, error };
}
