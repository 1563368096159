import axios, { AxiosError } from 'axios';
import { AXIOS_BASE_URL } from '../types/constants';

const getToken = () => {
  const token = localStorage.getItem('xa-network');
  return token ? JSON.parse(token).jwt : '';
};

const TIMEOUT = 60000;

export const axiosClient = axios.create({
  baseURL: AXIOS_BASE_URL,
  timeout: TIMEOUT,
});

axiosClient.interceptors.request.use((config) => {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    throw error;
  },
);
