import { axiosClient } from '@api/axiosClient';
import { QUERY_KEY } from '@constants/queryKeys';
import { useQuery } from '@tanstack/react-query';

export function useLinkedinRedirect() {
  const { data, isLoading, error } = useQuery({
    queryKey: [QUERY_KEY.LOGIN_LINKEDIN_REDIRECT],
    queryFn: async () => {
      const response = await axiosClient.get<string>('/auth/linkedin');
      return response.data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  return { linkedinRedirectUrl: data, isLoading, error };
}
