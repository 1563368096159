import { SCREEN_SIZE } from '@constants/enums';
import { useWindowSize } from '@hooks/useWindowSize';
import { LoadingOverlay, MultiSelect, ScrollArea, Skeleton } from '@mantine/core';
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTableStore } from '../store/useTableStore';
import { Investment } from '../types/InvestmentTable/type';
import { useGetInvestmentByXAMemberId } from '../useQueries/bases/XA Network Pipeline (Duplicate for Platform Build)/useXaNetworkPipeline';
import { currencyFormatter } from '../utils/helpers';
import { cn } from '../utils/utils';
import CompanyNameColumn from './MyPortfolioInvestmentTable/Columns/CompanyNameColumn';
import InvestmentDateColumn from './MyPortfolioInvestmentTable/Columns/InvestmentDateColumn';
import XaLeadColumn from './MyPortfolioInvestmentTable/Columns/XaLeadColumn';
import { MyPortfolioTableFilterFormValues } from './TableFilterContext';
import TableFilterModalForm from './TableFilterModalForm';
import TableView from './TableView';

type VisibleColumnState = Record<string, boolean>;

interface InvesmentTableProps {
  userRecordId: string;
  type?: string;
  isModalOpen?: boolean;
  onOpenModal?: () => void;
  onCloseModal?: () => void;
}

export const InvesmentTable = ({
  userRecordId,
  type,
  isModalOpen,
  onCloseModal,
}: InvesmentTableProps) => {
  const isMyInvestment = type === 'my-portfolio';
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [globalTableSearchText, setGlobalSearchText] = useTableStore((state) => [
    state.globalTableSearchText,
    state.setGlobalSearchText,
  ]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const { width } = useWindowSize();
  const isResponsived = width < SCREEN_SIZE.LG;

  const { data: memberInvestment = [], isLoading: isMemberInvestmentLoading } =
    useGetInvestmentByXAMemberId(userRecordId, type);

  const columns = React.useMemo<ColumnDef<Investment>[]>(
    () => [
      {
        id: 'index',
        meta: 'Index',
        maxSize: 20,
        minSize: 20,
        header: () => <div>No.</div>,
        cell: (info) => info.row.index + 1,
        accessorFn: (_, index) => index + 1, // accessor or id is required for some reason
        filterFn: 'equalsString', //note: normal non-fuzzy filter column - exact match required
      },
      {
        size: 180,
        meta: 'Company Name',
        accessorFn: (row) => row.company['Company Name'],
        accessorKey: 'company',
        header: () => 'Company Name',
        cell: (info) => {
          const company = info.row.original.company;
          return <CompanyNameColumn company={company} />;
        },
      },
      {
        id: 'dateOfInvestment',
        meta: 'Date Of Investment',
        accessorFn: (row) => {
          if (!row['Date Money in Bank (from Deal ID)']) return null;
          return dayjs(row['Date Money in Bank (from Deal ID)'].toString()).format(
            ` MMMM DD,  YYYY`,
          );
        },
        cell: (info) => {
          const dates = info?.row?.original['Date Money in Bank (from Deal ID)'];
          return <InvestmentDateColumn dates={dates} />;
        },
        header: () => <span>Date Of Investment</span>,
      },
      {
        meta: 'XA Lead',
        accessorKey: 'Lead Member',
        header: () => 'XA Lead',
        accessorFn: (row) => {
          const leaderName = row['Lead Member (from Company Name) (from Deal ID)']?.[0];
          return leaderName;
        },
        cell: (info) => {
          const leaderName = info.row.original['Lead Member (from Company Name) (from Deal ID)'];
          const leaderId = info.row.original.company['[Synced] Member (from [T] Investments)']?.[0];
          const leaderLinkedIn =
            info.row.original['Lead Member LinkedIn (from Company Name) (from Deal ID)'];
          return (
            <XaLeadColumn
              leaderId={leaderId}
              leaderName={leaderName}
              leaderLinkedIn={leaderLinkedIn}
            />
          );
        },
      },
      {
        meta: 'Funding Round',
        accessorKey: 'fundingRound',
        filterFn: 'includesString',
        accessorFn: (row) => {
          return row['Round (from Deal ID)']?.[0];
        },
        header: () => <span>Funding Round</span>,
        cell: (info) => (
          <span className='text-wrap'>{info.row.original['Round (from Deal ID)']}</span>
        ),
      },
      {
        meta: 'Amount Invested',
        id: 'investmentAmount',
        accessorKey: 'Investment Amount (USD)',
        header: 'Amount Invested (USD)',
        cell: (info) => (
          <span className={cn('text-primary-black')}>
            {currencyFormatter(info.row.original['Investment Amount (USD)'])}
          </span>
        ),
        accessorFn: (row) => row['Investment Amount (USD)'] + 'K',
        filterFn: 'equalsString',
      },
      // {
      //   meta: 'Current Value',
      //   id: 'currentValue',
      //   header: 'Current Value (USD)',
      //   cell: (info) => {
      //     if (!info.row.original['Estimated Current Value of Investment'])
      //       return <span>Not specified</span>;
      //     const lastestValuation = info.row.original['Estimated Current Value of Investment'];
      //     return <span>{currencyFormatter(lastestValuation)}</span>;
      //   },
      // },
    ],
    [],
  );

  const [columnVisibility, setColumnVisibility] = useState<VisibleColumnState>({});

  const tableData = React.useMemo(
    () => (isMemberInvestmentLoading ? Array(10).fill({}) : memberInvestment),
    [isMemberInvestmentLoading, memberInvestment],
  );

  const tableColumns = React.useMemo(() => {
    if (isMemberInvestmentLoading) {
      return columns.map((column) => ({
        ...column,
        cell: <Skeleton height={40} width={'auto'} />,
      }));
    }

    if (!isMyInvestment) {
      return columns.filter((column) => column.id !== 'investmentAmount');
    }

    return columns;
  }, [isMemberInvestmentLoading, isMyInvestment, columns]);

  const table = useReactTable({
    data: tableData,
    columns: tableColumns as any,
    state: {
      sorting,
      globalFilter: globalTableSearchText,
      columnVisibility,
      pagination: pagination,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalSearchText,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
  });

  const handleToggleEachColumnVisibility = (visibleColumns: string[]) => {
    table.getAllLeafColumns().map((column) => {
      if (visibleColumns.includes(column.columnDef.meta as string)) {
        column.toggleVisibility(true);
      } else {
        column.toggleVisibility(false);
      }
    });
  };

  // Toggle index column visibility on mobile
  useEffect(() => {
    if (isResponsived) {
      table.setColumnVisibility((prev) => ({
        ...prev,
        index: !isResponsived,
      }));
    }
  }, [table, isResponsived]);

  const visibleLeafColumns = table
    .getVisibleLeafColumns()
    .filter((column) => {
      if (column.id === 'investmentAmount') {
        return isMyInvestment;
      }
      return true;
    })
    .map((column) => column.columnDef.meta as string);

  const defaultVisibleColumns = useMemo(() => {
    return table
      .getAllLeafColumns()
      .filter((column) => {
        if (column.id === 'investmentAmount') {
          return isMyInvestment;
        }
        return true;
      })
      .map((column) => column.columnDef.meta as string);
  }, [table, isMyInvestment]);

  const handleSubmit = (values: MyPortfolioTableFilterFormValues) => {
    handleToggleEachColumnVisibility(values['visibleColumns']);
    onCloseModal && onCloseModal();
  };

  if (isMemberInvestmentLoading) {
    return (
      <div className='relative min-h-80'>
        <LoadingOverlay
          visible={isMemberInvestmentLoading}
          overlayProps={{
            backgroundOpacity: 0,
          }}
          loaderProps={{ color: 'red' }}
        />
      </div>
    );
  }

  if (memberInvestment.length === 0) {
    return <div>No investments have been made just yet.</div>;
  }

  return (
    <div className='flex flex-col h-full'>
      <div className='pb-12 hidden lg:block'>
        <MultiSelect
          withCheckIcon={true}
          placeholder={''}
          data={defaultVisibleColumns}
          nothingFoundMessage='Nothing found...'
          radius={'8px'}
          classNames={{
            input: 'rounded-xl',
          }}
          onChange={(value) => {
            handleToggleEachColumnVisibility(value);
          }}
          className='mb-4'
          value={visibleLeafColumns}
        />
      </div>

      <ScrollArea
        className='overflow-x-auto flex flex-col rounded-2xl relative flex-1'
        classNames={{ viewport: 'flex items-center md:items-stretch' }}
      >
        <TableView
          tableInstance={table}
          paginationConfig={{
            pageIndex: true,
            pageSize: true,
          }}
        />
      </ScrollArea>
      {isResponsived && (
        <TableFilterModalForm
          isModalOpen={!!isModalOpen}
          onCloseModal={onCloseModal}
          tableInstance={table}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};
export default memo(InvesmentTable);
