export enum SOCIAL_CONTACT_TYPE {
  OUTLOOK = 'Outlook',
  GMAIL = 'Gmail',
  WHATSAPP = 'Whatsapp',
  LINKEDIN = 'LinkedIn',
}

export enum INVESTMENT_STATUS {
  DD = 'DD',
  MEMBER_ASYNC = 'Member Async',
}

export const SCREEN_SIZE = {
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  '5XL': 1536,
};

export enum SortByOption {
  ASC = 'Ascending',
  DESC = 'Descending',
}

export enum CurrencyUnitAbbreviation {
  K = 'K',
  M = 'M',
  B = 'B',
  T = 'T',
}
