import { Box, Group, Radio } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useEffect, useState } from 'react';

interface RadioGroupFilterProps {
  name: string;
  title: string;
  defaultOptions: string[];
  formInstance: UseFormReturnType<any>;
}

const RadioGroupFilter = ({ name, title, defaultOptions, formInstance }: RadioGroupFilterProps) => {
  const { setFieldValue, getValues } = formInstance;
  const [formValue, setFormValue] = useState<string | null>(null);
  const currentValues = getValues();

  useEffect(() => {
    setFormValue(currentValues[name].toString());
  }, [currentValues, name]);

  const handleChange = (value: string) => {
    setFieldValue(name, [value]);
  };

  return (
    <div className='flex flex-col gap-2 font-sans'>
      <h5 className='text-lg leading-7 font-medium text-primary-black'>{title}</h5>

      <Radio.Group name={name} withAsterisk onChange={handleChange} value={formValue}>
        <Group mt='xs'>
          <Box className='flex flex-col gap-2 text-tetiary-black py-2'>
            {defaultOptions.map((value) => {
              return <Radio key={value} label={value} value={value} color='red' />;
            })}
          </Box>
        </Group>
      </Radio.Group>
    </div>
  );
};

export default RadioGroupFilter;
