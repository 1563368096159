const EMPTY_LINK = '#';
const LINKEDIN_LOGOUT_LINK = 'https://linkedin.com/m/logout';
const XA_NETWORK_PRIVACY_LINK = 'https://xanetwork.co/pdpa/';
const GMAIL_CONTACT_LINK =
  'https://mail.google.com/mail/?view=cm&fs=1&su=Support%20Request&to=contact@xanetwork.co&body=';
const OUTLOOK_CONTACT_LINK =
  'https://outlook.office.com/mail/deeplink/compose?to=contact@xanetwork.co&subject=Support%20Request&body=';
const WHATSAPP_CONTACT_LINK = 'https://wa.me/+6588258309';
const APPLICATION_FORM_INDIVIDUAL_COUPLE_LINK =
  'https://airtable.com/appAlFeN1abA9DV02/shrGeT9ea6yhaDMwe';
const STARTUP_SUBMISSION_FORM_LINK = 'https://airtable.com/appvwwAQFgv1im8NC/shrJb3ZBmToCNSGMh';
const HOW_TO_PARTNER_LINK = 'https://xanetwork.co/how-to-partner';
const OUR_MEMBERSHIP_CRITERIA_LINK =
  'https://docs.google.com/document/d/1gBcXiZVxk2eDhz9FYee23vy2-UnYL11uusilNGXQ7yY/edit?usp=sharing';
const FAMILY_OFFICES_ENQUIRY_FORM_LINK = 'https://airtable.com/appAlFeN1abA9DV02/shrz54ZWTWg5QCOok';
const COMPANY_DEFAULT_BANNER_LINK =
  'https://images.unsplash.com/photo-1472148439583-1f4cf81b80e0?q=80&w=3523&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
const COMPANY_DEFAULT_LOGO_LINK =
  'https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png';

export {
  EMPTY_LINK,
  LINKEDIN_LOGOUT_LINK,
  XA_NETWORK_PRIVACY_LINK,
  WHATSAPP_CONTACT_LINK,
  OUTLOOK_CONTACT_LINK,
  GMAIL_CONTACT_LINK,
  APPLICATION_FORM_INDIVIDUAL_COUPLE_LINK,
  STARTUP_SUBMISSION_FORM_LINK,
  HOW_TO_PARTNER_LINK,
  OUR_MEMBERSHIP_CRITERIA_LINK,
  FAMILY_OFFICES_ENQUIRY_FORM_LINK,
  COMPANY_DEFAULT_BANNER_LINK,
  COMPANY_DEFAULT_LOGO_LINK,
};
