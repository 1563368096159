import { CustomLink } from '@constants/nav';
import { Menu } from '@mantine/core';
import { Link, useLocation } from '@tanstack/react-router';
import { cn } from '@utils/utils';

type HamburgerCustomLinkProps = { className?: string } & CustomLink;

const HamburgerCustomLink = ({
  link,
  icon: Icon,
  label,
  className,
  onClick,
}: HamburgerCustomLinkProps) => {
  const { pathname } = useLocation();
  const isActive = link === pathname;
  return (
    <Link to={link} className='group text-unactive-link' activeProps={{ className: '!text-white' }}>
      <Menu.Item
        className={cn(
          'relative text-unactive-link px-5 py-3 sm:py-4 bg-primary-black text-xl active:bg-dark-red active:text-white',
          className,
          { 'text-white': isActive },
        )}
        color='white'
        component='div'
        classNames={{
          itemLabel: 'flex flex-row items-center gap-4',
        }}
        onClick={onClick}
      >
        {Icon && <Icon stroke={1.5} width={26} height={26} className='shrink-0' />}
        <span>{label}</span>
      </Menu.Item>
    </Link>
  );
};

export default HamburgerCustomLink;
