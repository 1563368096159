import { Burger } from '@mantine/core';

interface HamburgerMenuProps {
  isOpen: boolean;
  onToggle: () => void;
}

const HamburgerMenu = ({ isOpen, onToggle }: HamburgerMenuProps) => {
  return (
    <Burger
      opened={isOpen}
      lineSize={2}
      onClick={onToggle}
      color='white'
      aria-label='Toggle menu'
    />
  );
};

export default HamburgerMenu;
